/* LandingPage.css */

/* Card Style for Shared Components */
.card {
    border-radius: 0.5rem; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
    background-color: var(--tw-bg-opacity, 1) !important; /* Ensure background uses Tailwind bg color */
    padding: 1.5rem; /* Consistent padding inside cards */
  }
  
  /* Additional Responsive Overrides */
  @media (max-width: 768px) {
    .landing-page .section {
      padding: 2rem 1rem; /* Reduce padding on mobile for better fit */
    }
  }
  
  /* Custom Animations for Buttons or Links */
  .btn-hover-effect {
    transition: all 0.3s ease;
  }
  .btn-hover-effect:hover {
    transform: translateY(-3px);
  }
  