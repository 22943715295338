@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Nunito', sans-serif; /* Ensures body text uses Nunito */
}

h1, h2, h3, h4 {
  font-family: 'Pacifico', cursive; /* Applies retro script font to headings */
}

button {
  transition: all 0.3s ease-in-out; /* Smooth transitions on hover */
}

a {
  text-decoration: none; /* Removes underline from links */
}
