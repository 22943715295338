/* src/styles/animations.css */

@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fade-in {
    animation: fadeIn 1s ease-out forwards;
  }
  
  .animate-fade-in.delay-200 {
    animation-delay: 0.2s;
  }
  
  .animate-fade-in.delay-400 {
    animation-delay: 0.4s;
  }
  
  @keyframes bounceCustom {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .animate-bounce {
    animation: bounceCustom 2s infinite;
  }